<template>
  <div class="content">
    <el-container>
      <el-aside width="300px">
        <div class="left">
          <div class="block">
            <el-image
              style="width: 80px; height: 80px"
              class="left-header"
              :src="avater"
            >
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
            <span class="demonstration">{{ name }}</span>
            <div class="zzbtn" style="background-color: #ff1918; color: #fff" @click="handleChild(10)">
              <router-link to="/Pending/contribute" style="color: #fff"
                ><div>投稿</div></router-link
              >
            </div>
            <div class="zzbtn" style="border: 1px solid #666666" @click="handleChild(10)">
              <router-link to="/Pending/collection"
                ><div>期刊收藏</div></router-link
              >
            </div>
            <!-- <div id="app-2">
              <ul>
                <li
                  v-for="(item, index) in movies"
                  :class="{ active: index == isSelect }"
                  @click="bindColor(index)"
                >
                  {{ index }}：{{ item }}
                </li>
              </ul>
            </div> -->
          </div>
        </div>
        <el-aside width="300px">
          <div class="left-list">
            <div class="left-lists">
              <!--纵向子级选项卡-->
              <ul
                :class="currentId == index ? 'isShow' : 'hidden'"
                v-for="(item, index) in leftList"
                :key="index"
              >
                <li
                  :class="currentID2 == index ? 'active1' : ' '"
                  v-for="(itemc, index) in leftList"
                  :key="index"
                  @click="handleChild(index)"
                >
                  <router-link :to="itemc.path"
                    ><div style="width: 100%">
                      {{ itemc.title }}
                    </div></router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </el-aside>
      </el-aside>
      <el-container>
        <el-main>
          <div class="right">
			<keep-alive>
            <router-view  v-if="$route.meta.keepAlive"></router-view>
			 <!-- 这里是会被缓存的视图组件-->
			</keep-alive>
			<router-view v-if="!$route.meta.keepAlive">
			  <!-- 这里是不被缓存的视图组件 -->
			</router-view>
		
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { showsZZidentify } from "../../API/identify.js"
export default {
  components: {

  },
  data () {
    return {
      avater:require("../../assets/image/ygtx.jpg") ,//头像
      name: "",
      leftList: [
        { 'title': '订单列表', 'path': '/Pending/todoorder/?id=0' },
       // { 'title': '投稿中订单', 'path': '/Pending/contributeorder/?id=1' },
       // { 'title': '历史订单', 'path': '/Pending/history/?id=2' },
        { 'title': '账户设置', 'path': '/Pending/countsetting/?id=1' },
        { 'title': '账户余额', 'path': '/Pending/countbalance/?id=2' },
        // { 'title': '费用管理', 'path': '/Pending/costManager/?id=10' },
        { 'title': '智能查重', 'path': '/Pending/repete/?id=3' },
        { 'title': '我的红包', 'path': '/Pending/redpacket/?id=4' },
        { 'title': '我的优惠券', 'path': '/Pending/coupon/?id=5' },
        { 'title': '服务评价', 'path': '/Pending/servicepj/?id=6' },
        { 'title': '分享链接', 'path': '/Pending/sharelink/?id=7' }
      ],
      currentId: 0,
      currentID2: 10,
      isSelect: false,
      color: 'red',
      movies: []
    }
  },
  methods: {
    bindColor: function (index) {
      console.log(index)
      this.isSelect = index
    },
    handleChild: function (index) {
      this.currentID2 = index
    },
    //弹窗
    receive: function (row) {
      //显示弹框
      this.dialog = true;
    },
	showavater(){
		var id = localStorage.getItem('id');
		showsZZidentify(id).then(res => {
		  console.log(res)
		  if(res.data.data.headimg.length!=0){
			  this.avater = this.URL + res.data.data.headimg
		  }
		  this.name = res.data.data.loginname
		  this.$root.$emit('next1')
		   })
	}
  }, mounted () {
    if (this.$route.query.id) {
      this.currentID2 = this.$route.query.id
    }
   this.showavater()
   let _this = this //很重要把父路由的vue实例赋给_this
          _this.$root.$on('next', function () {  //监听next事件，这里$root很重要，不懂去官网介绍
              _this.showavater() //调用父路由中的方法
          })
  

  }
}
</script>

<style  scoped>
.content {
  width: 80%;
  height: auto;
  margin: 20px auto;
}
.left {
  width: 300px;
  padding-bottom: 20px;
  background-color: #ffffff;
  border-radius: 5px;
}
.left-header {
  border-radius: 50%;
  margin: 40px 110px 0 110px;
}
.zzbtn {
  width: 70%;
  margin: 10px auto;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #ff1918;
  box-sizing: border-box;
}
.left-name {
  margin-bottom: 50px;
  text-align: center;
  position: relative;
}

.demonstration {
  width: 300px;
  height: 40px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #141414;
  line-height: 40px;
  display: block;

  text-align: center;
}
.left-list {
  width: 300px;
  margin-top: 20px;
  border-radius: 5px;
  background: #ffffff;
}

.left-lists {
  width: 300px;
  cursor: pointer;
}
.left-list-wenzi {
  width: 300px;
  height: 50px;
  line-height: 50px;
  margin-left: 20px;
}
.active1 {
  border-left: 2px solid #ff4318;
  background-color: rgba(0, 0, 0, 0.1);
}
.tabcont {
  padding: 20px;
  position: absolute;
  left: 220px;
  top: 65px;
  right: 0;
  z-index: 100;
}
.tabcontent {
  display: none;
}

.left-lists ul {
  list-style: none;
  overflow-y: auto;
}
.left-lists ul li {
  height: 50px;
  line-height: 50px;
  text-align: left;
  padding-left: 20px;
  color: #333333;
}
.left-lists ul li span {
  display: block;
}
.isShow {
  display: block;
}
.hidden {
  display: none;
}
.el-main {
  padding: 0px 0px 20px 20px;
}
.right {
  width: 98%;
  height: auto;
  padding: 10px 0px 0px 10px;
  background: #ffffff;
}
.active {
  color: red;
}
</style>